import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button, Grid, Stack, CircularProgress, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import './App.css';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#87afc9',
      },
    },
  });

  /**
   * Object to update the snackbar type of message after uploading photos
   */
  const successObj = {
    state: "success",
    message: "",
  };

  /**
     * loading state is to track the display of the circularprogress spinner
     * success state is used to display the type and message in the snackbar, it uses the successObj as it's value
     * open state is used to track the display of the snackbar
     */
   const [loading, setLoading] = React.useState(false);
   const [success, setSuccess] = React.useState(successObj);
   const [open, setOpen] = React.useState(false);
   const [verr, setVError] = React.useState(false);

   /**
   * Create a reusable and customizable alert to be displayed inside the snackbar
   */
  const Alert = React.forwardRef(function Alert(
    props,
    ref,
    ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   * handele closing the snackbar
   */
  const handleClose = () => {
    setOpen(false);
  };

 const handleSubmit = async (event) => {
  event.preventDefault();

  if(event.target.bookingRefNum.value.trim() === '') {
    setVError(true);
    return;
  } else {
    setVError(false);
  }

  // Show the spinner and disble the upload button
  setLoading(true);
  
  /**
   * Call the API to download the photos
   * 
  */
   await axios.get(`${process.env.REACT_APP_APISERVER}/download?bookingid=${event.target.bookingRefNum.value}`, {responseType: 'blob'}).then( res => {
    setLoading(false);
    setSuccess({
        message: "Photos were successfully downloaded",
    });
    setOpen(true);

    let suggestedFileName = res.headers['content-disposition'].substring(22, res.headers['content-disposition'].length -1);
    
    if(suggestedFileName.length < 3) { suggestedFileName = 'santa-photos.zip'}
    
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', suggestedFileName);
    document.body.appendChild(link);
    link.click();
  }).catch( async error => {
    const errorMessage = await error.response.data.text();
    setLoading(false);
    setSuccess({
        state: "error",
        message: errorMessage
    });
    setOpen(true);
  });
 }

  return (
    <ThemeProvider theme={theme}>
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} className="leftSide">
          <Stack spacing={1} style={{}}>
            <Box style={{display: 'flex', paddingLeft: '50px', paddingTop: '50px'}}><img src="/img/logo.jpg" width="300" height="48" alt="Sheep River Health Trust" /></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><Typography variant="h2" style={{color: '#87afc9'}}>Download</Typography></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><Typography variant="h2" style={{color: '#2b4257'}}>your Santa Photos</Typography></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><Typography variant="subtitle1" style={{color: '#2b4257'}}>Please enter your booking number</Typography></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><Typography style={{color: '#2b4257', fontSize: '12px'}}>* case sensative, check your downloads folder for the ZIP file of your photos</Typography></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><TextField {...(verr && {error: true, helperText: 'Enter a value'})} id="bookingRefNum" variant="filled" size="small" label="Booking Number" focused sx={{ input: { color: '#2b4257' } }} /></Box>
            <Box style={{display: 'flex', paddingLeft: '80px'}}><Button type="submit" variant="contained" disabled={loading}>Download</Button> {(loading && <CircularProgress
                size={24}
                sx={{
                    position: 'relative',
                    top: '5px',
                    left: '-70px',
                }}
            />
            )}</Box>
            {(loading &&<Box style={{display: 'flex', paddingLeft: '80px'}}><Typography style={{color: '#2b4257', fontSize: '12px'}}>Download may take a few minutes</Typography></Box>)}
          </Stack>
        </Grid>
        <Grid item sx={{
          display: { xs: "none", lg: "block" }
        }} md={8}>
          <Box className="sideImage">
            <img src="/img/srht-banner.jpg" alt="Photos with Santa"/>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Box style={{margin: "20px", paddingTop: "5px", borderTop: "1px solid #87afc9"}}>
      <Grid container spacing={10}>
        <Grid item style={{width: "50%"}}>
          <Typography style={{color: '#2b4257', fontSize: 'small'}}>Copyright 2024 Sheep River Health Trust</Typography>
        </Grid>
        <Grid item style={{width: "50%", textAlign: "right"}}>
          <a href="https://sheepriverhealthtrust.ca/" target="_blank" rel="noreferrer"><HomeIcon style={{ color: '#2b4257' }} /></a>
          <a href="https://www.instagram.com/sheepriver_healthtrust/" target="_blank" rel="noreferrer"><InstagramIcon style={{ color: '#2b4257' }} /></a>
          <a href="https://www.facebook.com/SheepRiverHealthTrust" target="_blank" rel="noreferrer"><FacebookIcon style={{ color: '#2b4257' }}/></a>
        </Grid>
      </Grid>
    </Box>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={success.state} sx={{ width: '100%' }}>
            {success.message}
        </Alert>
    </Snackbar>
    </ThemeProvider>
  );
}

export default App;